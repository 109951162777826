import React from 'react';

import { Table } from 'rsuite';
import { colors } from '../../config/style';
import moment from 'moment';
const { Cell } = Table;

const renderColor = (data: string | number, shouldFormatCell: boolean) => {
	if (shouldFormatCell) {
		if (moment().isBefore(data) || data != 0) {
			return colors.success;
		} else {
			return colors.danger;
		}
	}
	return 'interfit';
};

const renderContent = (data: string | number, type?: string) => {
	const defaultRender = <span className="table-content-edit-span">{data}</span>;
	if (type) {
		switch (type) {
			case 'image':
				return typeof data === 'string' ? <img src={data} alt={data} width={100} /> : defaultRender;
			case 'boolean':
				return (
					<span className="table-content-edit-span">
						{data !== 'undefined' ? data.toString().toUpperCase() : 'Null'}
					</span>
				);

			case 'date':
				return (
					<span className="table-content-edit-span">
						{data !== 'undefined' && data ? (
							`${new Date(data).toLocaleDateString()} ${new Date(data).toLocaleTimeString()} `
						) : (
							'N/A'
						)}
					</span>
				);
			default:
				return defaultRender;
		}
	}
	return defaultRender;
};

export const FormattedCell = ({ rowData, dataKey, onClick, ...props }: any) => {
	let { caldiff, field } = props;
	const { fieldType = null } = field;
	return (
		<Cell {...props} style={{ color: renderColor(rowData[dataKey], caldiff) }}>
			{renderContent(rowData[dataKey], fieldType)}
		</Cell>
	);
};
