const fields = [
	{
		name: 'ID',
		editable: false,
		dataKey: 'id'
	},
	{
		name: 'Address',
		editable: true,
		dataKey: 'address',
		editType: 'textarea'
	},
	{
		name: 'Phone',
		editable: true,
		dataKey: 'phone'
	},
	{
		name: 'Facebook',
		editable: true,
		dataKey: 'facebook'
	},
	{
		name: 'Facebook Title',
		editable: true,
		dataKey: 'facebookTitle'
	},
	{
		name: 'Ig Title',
		editable: false,
		dataKey: 'igTitle'
	},

	{
		name: 'Ig',
		editable: false,
		dataKey: 'ig'
	},
	{
		name: 'Last Updated At',
		editable: false,
		dataKey: 'lastUpdateAt',
		fieldType: 'date'
	}
];

export default fields;
