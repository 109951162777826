import React, { useState } from "react";

import { DatePicker } from "antd";
import { Table } from "rsuite";
import { Upload, Progress, Select, Input } from "antd";
import { Button } from "../../components";
import axios from "axios";
import { imageApi } from "../../config/apiConfig";
import moment, { Moment } from "moment";
import { colors } from "../../config/style";
import { formatDateTimeForInput, formatMomentToString } from "../../utils";

const { Cell } = Table;
const { Option } = Select;
const { TextArea } = Input;

interface EditCellProps {
  rowData?: any;
  dataKey: any;
  onChange: any;
  editType?: any;
  editOptions?: any;
  editOptionType?: any;
  secondDataKey?: any;
  caldiff?: any;
  field?: any;
}

export const EditCell = ({
  secondDataKey,
  rowData,
  dataKey,
  onChange,
  editType,
  editOptions,
  editOptionType,
  caldiff,
  field,
  ...props
}: EditCellProps) => {
  const editing = rowData.editStatus === "EDIT";
  const [progress, setProgress] = useState(0);
  const [uploadStatus] = useState(false);
  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    fmData.append("photo", file);
    try {
      const { url } = imageApi.upload;
      const result = await axios.post(url, fmData, config);
      const { data } = result.data;
      onChange && onChange(rowData.id, dataKey, data);
      onSuccess("Ok");
    } catch (err) {
      onError({ err });
    }
  };

  const renderContent = (data: string | number, type?: string) => {
    const defaultRender = (
      <span className="table-content-edit-span">{data}</span>
    );
    if (type) {
      switch (type) {
        case "image":
          return typeof data === "string" ? (
            <img src={data} alt={data} width={100} />
          ) : (
            defaultRender
          );
        case "boolean":
          return (
            <span className="table-content-edit-span">
              {data !== "undefined" ? data.toString().toUpperCase() : "Null"}
            </span>
          );

        case "date":
          return (
            <span className="table-content-edit-span">
              {data !== 'undefined' && data ? (
                `${new Date(data).toLocaleDateString()} ${new Date(data).toLocaleTimeString()}`
              ) : (
                'N/A'
              )}
            </span>
          );
        default:
          return defaultRender;
      }
    }
    return defaultRender;
  };

  // handle different input type for editing
  let value = secondDataKey
    ? rowData[dataKey]
      ? rowData[dataKey][secondDataKey]
      : ""
    : rowData[dataKey];

  let inputForm = (
    <input
      className="rs-input"
      defaultValue={value}
      onChange={(event) => {
        onChange && onChange(rowData.id, dataKey, event.target.value);
      }}
    />
  );

  if (editType && editType === "OPTIONS") {
    inputForm = (
      <Select
        style={{ width: 200 }}
        defaultValue={value || ""}
        placeholder={value || ""}
        onChange={(value) => {
          onChange && onChange(rowData.id, dataKey, value);
        }}
      >
        {editOptions &&
          editOptions.length > 0 &&
          editOptions.map((option: any) => {
            const { value, displayName } = option;
            return (
              <Option key={`input${value}`} value={value}>
                {displayName}
              </Option>
            );
          })}
      </Select>
    );
  }

  if (editType && editType === "image") {
    inputForm = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {progress > 0 ? <Progress percent={progress} /> : null}

        <Upload
          accept="image/*"
          customRequest={uploadImage}
          className="image-upload-grid"
          showUploadList={{
            showRemoveIcon: false,
          }}
        >
          <Button
            loading={uploadStatus}
            style={{ width: "9em" }}
            buttonName="UPLOAD"
          />
        </Upload>
      </div>
    );
  }

  if (editType && editType === "textarea") {
    inputForm = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextArea
          value={value}
          rows={6}
          onChange={(event) => {
            onChange && onChange(rowData.id, dataKey, event.target.value);
          }}
        />
      </div>
    );
  }

  if (editType && editType === "date") {
    inputForm = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <DatePicker
          format="MM-DD-YYYY HH:mm:ss"
          showTime
          onChange={(event) => {
            const date = formatMomentToString(event);
            onChange && onChange(rowData.id, dataKey, date);
          }}
        />
      </div>
    );
  }

  const renderColor = (data: string | number, shouldFormatCell: boolean) => {
    if (shouldFormatCell) {
      if (moment().isBefore(data) || data != 0) {
        return colors.success;
      } else {
        return colors.danger;
      }
    }
    return "interfit";
  };
  const { fieldType = null } = field;

  return (
    <Cell
      {...props}
      style={{ color: renderColor(rowData[dataKey], caldiff) }}
      className={editing ? "table-content-editing" : ""}
    >
      {editing ? (
        inputForm
      ) : editType === "image" ? (
        <a rel="noopener noreferrer" href={value} target="_blank">
          {value}
        </a>
      ) : (
        renderContent(rowData[dataKey], fieldType)
      )}
    </Cell>
  );
};
