const menus = [
	{
		id: 10,
		name: 'Daily Operation',
		redirection: '/operation',
		allow: true
	},
	{
		id: 3,
		name: 'Tutor',
		redirection: '/tutor',
		allow: true
	},
	{
		id: 2,
		name: 'Member',
		redirection: '/member',
		allow: true
	},
	{
		id: 1,
		name: 'Dance Style',
		redirection: '/dancestyle',
		allow: true
	},

	{
		id: 4,
		name: 'Past Event',
		redirection: '/pastevent',
		allow: true
	},
	{
		id: 5,
		name: 'Price',
		redirection: '/price',
		allow: true
	},
	{
		id: 6,
		name: 'News',
		redirection: '/news',
		allow: true
	},
	{
		id: 7,
		name: 'Time Table',
		redirection: '/timetable',
		allow: true
	},
	{
		id: 8,
		name: 'Contact Us',
		redirection: '/contactus',
		allow: true
	},
	{
		id: 9,
		name: 'Operation Concept',
		redirection: '/operationconcept',
		allow: true
	}
];

export default menus;
