const fields = [
  {
    name: "ID",
    editable: false,
    dataKey: "id",
    width: 50,
  },
  {
    name: "Name",
    editable: true,
    dataKey: "name",
    width: 200,
  },
  // {
  //   name: "Email",
  //   editable: true,
  //   dataKey: "email",
  //   width: 250,
  // },
  {
    name: "Phone",
    editable: true,
    dataKey: "phone",
    width: 150,
  },

  {
    name: "Current Course",
    editable: true,
    dataKey: "currentCourse",
    width: 200,
    caldiff: true,
  },
  {
    name: "Expire Date",
    editable: true,
    dataKey: "expireDate",
    fieldType: "date",
    width: 200,
    caldiff: true,
    editType: "date",
  },
  {
    name: "Birthday",
    editable: true,
    dataKey: "dob",
    width: 200,
  },
  {
    name: 'Password',
    editable: true,
    dataKey: 'password',
    width: 200
  },
  {
    name: "Emergency contact name",
    editable: true,
    dataKey: "e_contact_name",
    width: 200,
  },
  {
    name: "Emergency contact",
    editable: true,
    dataKey: "e_contact",
    width: 200,
  },
  {
    name: "Create At",
    editable: false,
    dataKey: "createAt",
    fieldType: "date",
    width: 200,
  },
];

export default fields;
