import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
// Components
import {
  MainContainer,
  Subtitle,
  CustomTable,
  Button,
  Text,
} from "../../components";
import { message, Select } from "antd";
import hisFields from "./hisFields";
import moment from "moment";
// action
import { useListStore } from "../../stores";
import { DatePicker, Drawer } from "antd";

const { Option } = Select;

const renderChild = ({
  data,
  memberList,
  takeCourse,
  id,
  selected,
  setSelected,
  handleDate,
  handleMonth,
  visible,
  setVisible,
}: any) => {
  let student: any = localStorage.getItem("member");
  const filterList =
    memberList && memberList.length > 0
      ? memberList.filter(
          (item: any) =>
            item.currentCourse > 0 && moment().isBefore(moment(item.expireDate))
        )
      : [];
  student = student ? JSON.parse(student) : student;
  const handleCreate = async () => {
    const memberIds = selected ? selected.toString().split(",") : [];

    const removedChar = memberIds.map((item: string) => {
      return item.split("-")[0];
    });

    if ((memberIds && memberIds.length === 0) || !memberIds) {
      message.error("No Student Selected");
      return;
    }
    await takeCourse(id, memberIds);
    message.info("Create Record Successfully");
  };

  const handleChange = (value: any) => {
    setSelected(value);
  };

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {student && (
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Name" />
            <Text
              style={{ marginTop: 0, marginLeft: 10 }}
              text={student.name}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Phone" />
            <Text
              style={{ marginTop: 0, marginLeft: 10 }}
              text={student.phone}
            />
          </div>
        </div>
      )}

      <Subtitle subtitle="Attendant Form" preset="page-subtitle" />

      <div style={{ width: "100%", marginBottom: 20 }}>
        <Select
          mode="multiple"
          value={selected}
          allowClear
          style={{ width: "100%", marginBottom: 20 }}
          placeholder="Please select"
          onChange={handleChange}
        >
          {filterList.map((member: any, index: number) => {
            const { name, phone, expireDate, currentCourse, id } = member;
            const disable =
              !expireDate ||
              (expireDate && moment().isAfter(moment(expireDate))) ||
              currentCourse <= 0;

            return (
              <Option
                disabled={disable}
                key={index.toString()}
                value={`${id}-${phone}`}
              >
                {id} - {name} ({phone})
              </Option>
            );
          })}
        </Select>
        <Button
          onClick={handleCreate}
          buttonName={"Submit"}
          style={{ width: "25%" }}
        />
      </div>

      <Subtitle subtitle="Teaching Class History" preset="page-subtitle" />

      <DatePicker
        style={{ width: "50%" }}
        onChange={handleMonth}
        picker="month"
      />

      <Drawer
        width={"60%"}
        title="Monthly Record"
        placement="right"
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Text text={"You can filter the record by date"} />
        <DatePicker
          onChange={handleDate}
          style={{ marginBottom: 20, marginTop: 20 }}
        />
        <Text
          style={{ fontWeight: "bold " }}
          text={`Amount of Student : ${data.length}`}
        />

        <div style={{ width: "100%", marginTop: 20 }}>
          {data && data.length > 0 && (
            <CustomTable
              dataList={data || []}
              fields={hisFields}
              allowDelete={false}
              rowHeight={50}
              allowRedirect={false}
              allowEdit={false}
            />
          )}
        </div>
      </Drawer>
      {/* Table */}
    </div>
  );
};

const DanceStylePage = () => {
  const [categoryList, setCategoryList] = useState<any>([]);
  const [selected, setSelected] = useState();
  const [visible, setVisible] = useState<boolean>(false);

  const {
    memberList,
    history,
    getTakeCourseHis,
    creatOrUpdateCourse,
    getMember,
    takeCourse,
  } = useListStore();

  let { id } = useParams<any>();

  useEffect(() => {}, [selected]);
  useEffect(() => {
    getTakeCourseHis(id);
    getMember();
  }, [getTakeCourseHis, getMember]);

  useEffect(() => {
    setCategoryList(history);
  }, [history]);

  useEffect(() => {}, [memberList]);

  const handleDate = (value: any) => {
    const filterList = value
      ? history.filter((item) => value.isSame(item.createAt, "day"))
      : history;
    setCategoryList(filterList);
  };

  const handleMonth = (value: any) => {
    const filterList = value
      ? history.filter(
          (item) =>
            value.isSame(item.createAt, "month") &&
            value.isSame(item.createAt, "year")
        )
      : history;
    setCategoryList(filterList);
    value && setVisible(true);
  };

  let config = {
    data: categoryList,
    creatOrUpdateCourse,
    memberList,
    id,
    selected,
    setSelected,
    takeCourse,
    handleDate,
    handleMonth,
    visible,
    setVisible,
  };

  return <MainContainer child={() => renderChild(config)} />;
};

export default observer(DanceStylePage);
