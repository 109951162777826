const fields = [
	{
		name: 'ID',
		editable: false,
		dataKey: 'id'
	},
	{
		name: 'Introduction',
		editable: true,
		dataKey: 'introduction',
		fieldType: 'textarea',
		editType: 'textarea'
	}
];

export default fields;
