import { ApiStore } from "./api";
import { action, observable } from "mobx";
import { message } from "antd";
import {
  danceStyleApi,
  tutorApi,
  newsApi,
  pastEventApi,
  contactUsApi,
  timetableApi,
  priceApi,
  memberApi,
  operationConceptApi,
  courseApi,
} from "../config/apiConfig";
import { setLocalStorage } from "../utils";

export class ListStore {
  @observable list: any[] = [];
  @observable history: any[] = [];
  @observable tutorList: any[] = [];
  @observable memberList: any[] = [];

  @action.bound
  async getDanceStyleList() {
    try {
      const { url, method } = danceStyleApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.list = data;
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async creatOrUpdateDanceStyle(params: any) {
    try {
      const { url, method } = danceStyleApi.createOrUpdate;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);
      await this.getDanceStyleList();
      message.info("create dance style Successfully");
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async deleteDanceStyle(params: any) {
    try {
      const { url, method } = danceStyleApi.delete;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, { id: params });
      await this.getDanceStyleList();
      message.info("Delete Successfully");
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async getTutorList() {
    try {
      const { url, method } = tutorApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.tutorList = data;
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async creatOrUpdateTutor(params: any) {
    try {
      const { url, method } = tutorApi.createOrUpdate;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);
      await this.getTutorList();
      params.id
        ? message.info("Update Tutor Successfully")
        : message.info("Create Tutor Successfully");
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async deleteTutor(params: any) {
    try {
      const { url, method } = tutorApi.delete;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, { id: params });
      message.info("Delete Successfully");
      await this.getTutorList();

      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async getNewsList() {
    try {
      const { url, method } = newsApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.list = data;
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async creatOrUpdateNews(params: any) {
    try {
      const { url, method } = newsApi.createOrUpdate;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);
      await this.getNewsList();

      if (params.id) {
        message.info("Update News Successfully");
      } else {
        message.info("Create News Successfully");
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async deleteNews(params: any) {
    try {
      const { url, method } = newsApi.delete;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, { id: params });
      message.info("Delete Successfully");
      await this.getNewsList();

      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async getPastEventList() {
    try {
      const { url, method } = pastEventApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.list = data;
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async creatOrUpdatePastEvent(params: any) {
    try {
      const { url, method } = pastEventApi.createOrUpdate;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);
      await this.getPastEventList();

      if (params.id) {
        message.info("Update past event Successfully");
      } else {
        message.info("Create past event Successfully");
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async deletePastEvent(params: any) {
    try {
      const { url, method } = pastEventApi.delete;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, { id: params });
      message.info("Delete Successfully");
      await this.getPastEventList();

      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async getContactUs() {
    try {
      const { url, method } = contactUsApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.list = [data];
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async creatOrUpdateContactUs(params: any) {
    try {
      const { url, method } = contactUsApi.createOrUpdate;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);
      await this.getContactUs();
      message.info("Update contact us Successfully");

      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async getPrice() {
    try {
      const { url, method } = priceApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.list = [data];
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async creatOrUpdatePrice(params: any) {
    try {
      const { url, method } = priceApi.createOrUpdate;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);
      await this.getPrice();
      message.info("Update price Successfully");

      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async getTimeTable() {
    try {
      const { url, method } = timetableApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.list = [data];
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async creatOrUpdateTimeTable(params: any) {
    try {
      const { url, method } = timetableApi.createOrUpdate;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);
      await this.getPrice();
      message.info("Update time table Successfully");

      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async getMember() {
    try {
      const { url, method } = memberApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.memberList = data;
      return data;
    } catch (error) {
      return [];
    }
  }

  @action.bound
  async createMember(params: any) {
    try {
      const { url, method } = memberApi.create;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);
      await this.getMember();
      message.info("Update member Successfully");

      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async updateMember(params: any) {
    try {
      const { url, method } = memberApi.update;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);
      await this.getMember();
      message.info("Update member Successfully");

      return true;
    } catch (error) {
      return false;
    }
  }
  @action.bound
  async getOperationConcept() {
    try {
      const { url, method } = operationConceptApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.list = [data];
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async creatOrUpdateOperation(params: any) {
    try {
      const { url, method } = operationConceptApi.createOrUpdate;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);
      await this.getOperationConcept();
      message.info("Update time table Successfully");

      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async getMemberCourse(id: string) {
    try {
      const { url, method } = courseApi.list;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url.replace("{ID}", id));
      const { data, status } = result;
      this.list = data || [];
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async getTakeCourseHisStudent(id: string) {
    try {
      const { url, method } = courseApi.takeCourseHisBysStudent;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url.replace("{ID}", id));
      const { data, status } = result;
      this.history = data || [];
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async getTakeCourseHisAll() {
    try {
      const { url, method } = courseApi.takeCourseAllHis;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url);
      const { data, status } = result;
      this.history = data || [];
      return data;
    } catch (error) {
      return [];
    }
  }

  @action.bound
  async creatOrUpdateCourse(params: any) {
    try {
      const { url, method } = courseApi.createOrUpdate;
      // get call api method
      const { callApi } = new ApiStore();
      await callApi(method, url, params);

      const newMembers: any = await callApi(
        memberApi.list.method,
        memberApi.list.url
      );

      const newMember = newMembers.data.find(
        (member: any) => member.id == params.memberId
      );
      setLocalStorage("member", JSON.stringify(newMember));
      await this.getMemberCourse(params.memberId);

      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async updatePurchaseRecord(params: any) {
    try {
      const { url, method } = courseApi.updatePurchaseRecord;
      // get call api method
      const { callApi } = new ApiStore();
      const result = await callApi(method, url, params);

      const newMembers: any = await callApi(
        memberApi.list.method,
        memberApi.list.url
      );

      const newMember = newMembers.data.find(
        (member: any) => member.id == params.memberId
      );
      setLocalStorage("member", JSON.stringify(newMember));
      await this.getMemberCourse(params.memberId);
      message.info("Changed purchase record");
      return true;
    } catch (error) {
      message.error(
        error && error.message ? error.message : "Please try again later"
      );
      return false;
    }
  }

  @action.bound
  async getTakeCourseHis(id: string) {
    try {
      const { url, method } = courseApi.takeCourseHis;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url.replace("{ID}", id));
      const { data, status } = result;
      this.history = data || [];
      return true;
    } catch (error) {
      return false;
    }
  }

  @action.bound
  async takeCourse(tutorId: string, memberId: any) {
    try {
      const { url, method } = courseApi.takeCourse;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url, {
        memberIds: memberId,
        tutorId,
      });
      const { data, status } = result;
      this.history = data || [];
      this.getTakeCourseHis(tutorId);
      this.getMember();
      return true;
    } catch (error) {
      return false;
    }
  }

  is_numeric(str: string) {
    return /^\d+$/.test(str);
  }

  @action.bound
  async updateTakeCourse(params: any) {
    try {
      const { url, method } = courseApi.updateTakeCourse;
      // get call api method
      const { callApi } = new ApiStore();
      if (params.tutorName && this.is_numeric(params.tutorName)) {
        params.tutorId = params.tutorName;
      }

      const result: any = await callApi(method, url, params);
      const { data, status } = result;
      const { memberId } = params;
      message.info("Update Successfully");
      await this.getTakeCourseHisStudent(memberId);
      return true;
    } catch (error) {
      message.info("Failed to update");

      return false;
    }
  }
}

export const STORE_LIST = "listStore";
