import { action, observable } from "mobx";
import { setLocalStorage, removeAllLocalStorage } from "../utils";
import { history } from "./router";
import { message } from "antd";
import { authApi } from "../config/apiConfig";
import { ApiStore } from "./api";

export class AuthStore {
  @observable twoFaQrcode: string = "";

  @action.bound
  async login(username: string, password: string) {
    try {
      const { url, method } = authApi.login;
      // get call api method
      const { callApi } = new ApiStore();
      const result: any = await callApi(method, url, { username, password });
      const { data, status } = result;
      setLocalStorage("admin", JSON.stringify(data));
      setLocalStorage("logined", "yes");
      history.push("dancestyle");
      return status;
    } catch (error) {
      message.error(error && error.message ? error.message : "Login failed");
      return false;
    }
  }

  @action.bound
  async logout() {
    try {
      removeAllLocalStorage();
      history.push("/login");
      return true;
    } catch (error) {
      removeAllLocalStorage();
      history.push("/login");
    }
  }
}

export const STORE_AUTH = "authStore";
