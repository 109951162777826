const fields = [
  {
    name: "ID",
    editable: false,
    dataKey: "id",
    width: 50,
  },
  {
    name: "Amount",
    editable: true,
    editType: "OPTIONS",
    dataKey: "amount",
    width: 200,
  },
  {
    name: "Expire Date",
    editable: false,
    dataKey: "expireDate",
    fieldType: "date",
    width: 200,
  },
  {
    name: "Create At",
    editable: false,
    dataKey: "createAt",
    fieldType: "date",
    width: 200,
  },
];

export default fields;
