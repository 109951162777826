import React from "react";
import { ButtonType, ButtonShape, ButtonHtmlType } from "./types";
import { Button as AntdButton } from "antd";

// Style
import { colors, fonts } from "../../config/style";

interface ButtonProps {
  type?: ButtonType;
  buttonName: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  shape?: ButtonShape;
  disabled?: boolean;
  htmlType?: ButtonHtmlType;
  className?: string;
  style?: object;
  icon?: string;
  loading?: boolean;
}

export const Button = (props: ButtonProps) => {
  let {
    type,
    buttonName,
    onClick,
    shape,
    disabled,
    htmlType,
    className,
    style,
    loading,
  } = props;
  return (
    <AntdButton
      type={type || "primary"}
      onClick={onClick || undefined}
      shape={shape || undefined}
      disabled={disabled || false}
      htmlType={htmlType || "button"}
      className={className || undefined}
      loading={loading}
      style={{
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        fontFamily: fonts.textFontFamily,
        fontSize: fonts.textFontSize,
        width: "100%",
        ...style,
      }}
    >
      {buttonName}
    </AntdButton>
  );
};
