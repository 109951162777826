const fields = [
	{
		name: 'ID',
		editable: false,
		dataKey: 'id'
	},
	{
		name: 'Image',
		editable: true,
		dataKey: 'imageLink',
		editType: 'image'
	},
	{
		name: 'Last Updated At',
		editable: false,
		dataKey: 'lastUpdateAt',
		fieldType: 'date'
	}
];

export default fields;
