import React, { useEffect, useCallback } from "react";

// Component
import { Table } from "rsuite";
import { ActionCell } from "./actionCell";
import { EditCell } from "./editCell";
import { DeleteCell } from "./deleteCell";
import { RedirectCell } from "./redirectCell";
import { EditPasswordCell } from "./editPasswordCell";
import { FormattedCell } from "./formattedCell";
import { Search } from "../search";
import { RemoveTwoFaCell } from "./removeTwoFaCell";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { history } from "../../stores";
import { setLocalStorage } from "../../utils";
import moment from "moment";
import { colors } from "../../config/style";
const { Column, HeaderCell, Pagination } = Table;

interface CustomTableProps {
  dataList?: Array<object>;
  fields: Array<object>;
  allowEdit?: boolean;
  allowDelete?: boolean;
  allowRedirect?: boolean;
  refPage?: string;
  searchTxt?: string;
  updateAction?: Function;
  deletedAction?: Function;
  redirectLink?: string;
  handleLockAction?: Function;
  handleTwoFaAction?: Function;
  editOptions?: object;
  rowHeight?: number;
}

export const CustomTable = (props: CustomTableProps) => {
  let {
    dataList,
    fields,
    allowEdit = true,
    allowDelete = true,
    allowRedirect = false,
    refPage = null,
    searchTxt,
    updateAction,
    deletedAction,
    redirectLink,
    handleLockAction,
    handleTwoFaAction,
    editOptions = null,
    rowHeight = 200,
  } = props;
  const [data, setData] = React.useState(dataList);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [displayLength, setDisplayLength] = React.useState(10);
  const [sortType, setSortType] = React.useState(undefined);
  const [sortColumn, setSortColumn] = React.useState(undefined);
  const length = (dataList && dataList.length) || 0;

  const getFilterData = useCallback(() => {
    let filterData =
      dataList &&
      dataList.filter((_v, i) => {
        const start = displayLength * (page - 1);
        const end = start + displayLength;
        return i >= start && i < end;
      });
    return setData(filterData);
  }, [dataList, displayLength, page]);

  const getSortData = useCallback(() => {
    if (sortColumn && sortType) {
      return (
        dataList &&
        dataList.sort((a, b) => {
          let x: any = a[sortColumn!];
          let y: any = b[sortColumn!];
          if (typeof x === "string" && typeof y === "string") {
            if (sortType === "asc") {
              return x.localeCompare(y);
            } else {
              return y.localeCompare(x);
            }
          }
          if (sortType === "asc") {
            return x - y;
          } else {
            return y - x;
          }
        })
      );
    }
    return dataList;
  }, [sortColumn, sortType, dataList]);

  useEffect(() => {}, [fields]);

  useEffect(() => {
    setLoading(true);
    getFilterData();
    getSortData();
    setLoading(false);
  }, [getFilterData, getSortData, setLoading]);

  const handleChange = (id: number, key: any, value: any) => {
    const nextData = Object.assign([], data);
    let nextDataVal: any = nextData.find((item: any) => item.id === id);
    if (nextDataVal) {
      nextDataVal[key] = value;
      setData(nextData);
    }
  };

  const handleEditState = (id: number) => {
    const nextData = Object.assign([], data);
    const activeItem: any = nextData.find((item: any) => item.id === id);
    activeItem.editStatus = activeItem.editStatus ? null : "EDIT";
    setData(nextData);
    updateAction && activeItem.editStatus === null && updateAction(activeItem);
  };

  const handleDeleteState = (id: string) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Do you confirm to delete this record?`,
      okText: "Confirm",
      cancelText: "BACK",
      onOk: () => {
        deletedAction && deletedAction(id);
      },
    });
  };

  const handleLock = (id: string) => {
    const nextData = Object.assign([], data);
    const activeItem: any = nextData.find((item: any) => item.id === id);

    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Do you confirm to ${
        activeItem.status ? "lock" : "unlock"
      } this user?`,
      okText: "Confirm",
      cancelText: "BACK",
      onOk: () => {
        handleLockAction && handleLockAction(activeItem);
      },
    });
  };

  const handleTwoFa = (id: string) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Do you confirm to re-enable the TwoFa?`,
      okText: "Confirm",
      cancelText: "BACK",
      onOk: () => {
        const nextData = Object.assign([], data);
        const activeItem: any = nextData.find((item: any) => item.id === id);

        handleTwoFaAction && handleTwoFaAction(activeItem);
      },
    });
  };

  const handleChangePage = (dataKey: number) => {
    setPage(dataKey);
  };

  const handleRedirect = (rowData: any) => {
    setLocalStorage("member", JSON.stringify(rowData));
    history.push(`${redirectLink}/${rowData.id}`, rowData);
  };

  const handleChangeLength = (dataKey: number) => {
    setPage(1);
    setDisplayLength(dataKey);
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(false);

    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleFilter = (value: any, e: any) => {
    e.preventDefault();
    let filterData =
      dataList &&
      dataList.filter((val) => {
        let filterObject = Object.values(val).filter((v: any) => {
          return v && v.toString().toLowerCase().includes(value.toLowerCase());
        }, []);
        if (filterObject && filterObject.length > 0) {
          return val;
        } else {
          return null;
        }
      }, []);
    return setData(filterData);
  };

  const renderColor = (data: string | number, shouldFormatCell: boolean) => {
    if (shouldFormatCell) {
      if (moment().isBefore(data) || data != 0) {
        return colors.success;
      } else {
        return colors.danger;
      }
    }
    return "interfit";
  };

  return (
    <div>
      {/* Search */}
      <Search
        placeholder={searchTxt || "Filter Data"}
        onSearch={handleFilter}
      />

      {/* Table */}
      <Table
        height={1000}
        data={data}
        rowHeight={rowHeight}
        loading={loading}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        autoHeight
        affixHeader
        style={{ marginTop: 20 }}
      >
        {fields.map((field: any, i: number) => {
          let {
            name,
            editable,
            dataKey,
            secondDataKey,
            width,
            fixedCell,
            caldiff,
            sortable = false,
            editType = "text",
            editOptionType = "",
          } = field;

          return (
            <Column
              key={i}
              width={width || 350}
              resizable
              sortable={sortable}
              fixed={fixedCell ? "right" : undefined}
            >
              <HeaderCell>{name}</HeaderCell>
              {editable ? (
                <EditCell
                  editType={editType}
                  dataKey={dataKey}
                  secondDataKey={secondDataKey}
                  editOptions={editOptions}
                  editOptionType={editOptionType}
                  onChange={handleChange}
                  caldiff={caldiff}
                  field={field}
                />
              ) : (
                <FormattedCell
                  dataKey={dataKey}
                  caldiff={caldiff}
                  field={field}
                />
              )}
            </Column>
          );
        })}

        {allowRedirect ? (
          <Column fixed="right" width={30}>
            <HeaderCell />
            <RedirectCell dataKey="id" onClick={handleRedirect} />
          </Column>
        ) : null}

        {allowEdit ? (
          <Column fixed="right" width={30}>
            <HeaderCell />
            <ActionCell dataKey="id" onClick={handleEditState} />
          </Column>
        ) : null}

        {allowDelete ? (
          <Column fixed="right" width={30}>
            <HeaderCell />
            <DeleteCell dataKey="id" onClick={handleDeleteState} />
          </Column>
        ) : null}

        {refPage && refPage === "users" ? (
          <Column fixed="right" width={30}>
            <HeaderCell />
            <EditPasswordCell dataKey="id" onClick={handleLock} />
          </Column>
        ) : null}

        {refPage && refPage === "users" ? (
          <Column fixed="right" width={30}>
            <HeaderCell />
            <RemoveTwoFaCell dataKey="id" onClick={handleTwoFa} />
          </Column>
        ) : null}
      </Table>

      {/* Pagination */}
      <Pagination
        lengthMenu={[
          {
            value: 10,
            label: 10,
          },
          {
            value: 25,
            label: 25,
          },
          {
            value: 50,
            label: 50,
          },
          {
            value: 100,
            label: 100,
          },
        ]}
        activePage={page}
        displayLength={displayLength}
        total={length}
        onChangePage={handleChangePage}
        onChangeLength={handleChangeLength}
      />
    </div>
  );
};
