import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

// Components
import { MainContainer, Subtitle, CustomTable, ModelForm, Text } from '../../components';
import fields from './fields';
import hisFields from './hisFields';
import { DatePicker, Drawer } from 'antd';
import moment from 'moment';
// action
import { useListStore } from '../../stores';

const renderChild = ({
	data,
	deleteTutor,
	creatOrUpdateTutor,
	history,
	handleDate,
	handleMonth,
	visible,
	setVisible
}: any) => {
	const handleCreate = (params: Object) => {
		creatOrUpdateTutor(params);
	};

	const handleUpdate = (params: object) => {
		creatOrUpdateTutor(params);
	};

	const handleDeletion = (id: string) => {
		deleteTutor(id);
	};

	return (
		<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Subtitle subtitle="Tutor" preset="page-subtitle" />
				<ModelForm
					formTitle="Create List Item"
					formName={'newNewletter'}
					submitBtnName={'CREATE'}
					submitBtnType={'submit'}
					submitBtnClass="login-button"
					onSubmitAction={handleCreate}
					fields={[
						{
							id: 0,
							name: 'Name',
							type: 'text',
							rules: { required: true, message: 'Please input Name !' }
						},
						{
							id: 1,
							name: 'Style',
							type: 'text',
							rules: { required: true, message: 'Please input detail !' }
						},
						{
							id: 2,
							name: 'Level',
							type: 'text',
							rules: { required: true, message: 'Please input detail !' }
						}
					]}
				/>
			</div>
			{/* Table */}
			<div style={{ width: '100%', marginTop: 20 }}>
				<CustomTable
					dataList={data || []}
					fields={fields}
					rowHeight={50}
					updateAction={handleUpdate}
					deletedAction={handleDeletion}
					redirectLink={'tutor/details'}
					allowRedirect
				/>
			</div>

			<Subtitle subtitle="Class Record" preset="page-subtitle" style={{ marginTop: 20 }} />

			<DatePicker style={{ width: '50%' }} onChange={handleMonth} picker="month" />

			<Drawer
				width={'60%'}
				title="Monthly Record"
				placement="right"
				onClose={() => setVisible(false)}
				visible={visible}
			>
				<Text text={'You can filter the record by date'} />
				<DatePicker onChange={handleDate} style={{ marginBottom: 20, marginTop: 20 }} />
				<Text style={{ fontWeight: 'bold ' }} text={`Amount of Student : ${history.length}`} />

				<div style={{ width: '100%', marginTop: 20 }}>
					<CustomTable
						dataList={history || []}
						fields={hisFields}
						rowHeight={50}
						allowDelete={false}
						allowEdit={false}
						allowRedirect={false}
					/>
				</div>
			</Drawer>
		</div>
	);
};

const DanceStylePage = () => {
	const [ categoryList, setCategoryList ] = useState<any>([]);
	const [ historyList, setHistoryList ] = useState<any>([]);
	const [ visible, setVisible ] = useState<boolean>(false);

	const { history, tutorList, getTutorList, creatOrUpdateTutor, deleteTutor, getTakeCourseHisAll } = useListStore();

	useEffect(() => {
		getTutorList();
		getTakeCourseHisAll();
	}, []);

	useEffect(
		() => {
			tutorList && tutorList.length > 0 && setCategoryList(tutorList);
		},
		[ tutorList ]
	);

	useEffect(
		() => {
			history && history.length > 0 && setHistoryList(history);
		},
		[ history ]
	);

	const handleDate = (value: any) => {
		const filterList = value ? history.filter((item) => value.isSame(item.createAt, 'day')) : history;
		setHistoryList(filterList);
	};

	const handleMonth = (value: any) => {
		const filterList = value
			? history.filter((item) => value.isSame(item.createAt, 'month') && value.isSame(item.createAt, 'year'))
			: history;
		setHistoryList(filterList);
		value && setVisible(true);
	};

	let config = {
		data: categoryList,
		creatOrUpdateTutor,
		deleteTutor,
		history: historyList,
		handleDate,
		handleMonth,
		visible,
		setVisible
	};

	return <MainContainer child={() => renderChild(config)} />;
};

export default observer(DanceStylePage);
