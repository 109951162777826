import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

// Components
import {
  MainContainer,
  Subtitle,
  CustomTable,
  ModelForm,
} from "../../components";
import fields from "./fields";

// action
import { useListStore } from "../../stores";

const renderChild = ({ data, creatOrUpdateContactUs }: any) => {
  const handleUpdate = (params: object) => {
    creatOrUpdateContactUs(params);
  };

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Subtitle subtitle="Contact Us" preset="page-subtitle" />
      </div>
      {/* Table */}
      <div style={{ width: "100%", marginTop: 20 }}>
        <CustomTable
          allowDelete={false}
          dataList={data || []}
          fields={fields}
          updateAction={handleUpdate}
          redirectLink={"news"}
        />
      </div>
    </div>
  );
};

const DanceStylePage = () => {
  const [categoryList, setCategoryList] = useState<any>([]);

  const { list, getContactUs, creatOrUpdateContactUs } = useListStore();

  useEffect(() => {
    getContactUs();
  }, [getContactUs]);

  useEffect(() => {
    list && list.length > 0 && setCategoryList(list);
  }, [list]);

  let config = {
    data: categoryList,
    creatOrUpdateContactUs,
  };

  return <MainContainer child={() => renderChild(config)} />;
};

export default observer(DanceStylePage);
