import React from 'react';
import { history } from '../../stores';

// Components
import { Text } from '../../components';
import { Tooltip } from 'antd';
import {
	DingdingOutlined,
	SnippetsOutlined,
	UserOutlined,
	UserSwitchOutlined,
	HighlightOutlined,
	MoneyCollectOutlined,
	ThunderboltOutlined,
	FieldTimeOutlined,
	ContactsOutlined
} from '@ant-design/icons';

// Style
import { colors, fonts } from '../../config/style';

// Menu Items
import menus from './menu';

const ICON = {
	color: colors.background,
	fontSize: 18
};

interface SideMenuProps {}

const renderIcon = (id: number) => {
	switch (id) {
		case 1:
			return <DingdingOutlined style={ICON} />;
		case 2:
			return <UserOutlined style={ICON} />;
		case 3:
			return <UserSwitchOutlined style={ICON} />;
		case 4:
			return <HighlightOutlined style={ICON} />;
		case 5:
			return <MoneyCollectOutlined style={ICON} />;
		case 6:
			return <ThunderboltOutlined style={ICON} />;
		case 7:
			return <FieldTimeOutlined style={ICON} />;
		case 8:
			return <ContactsOutlined style={ICON} />;
		case 9:
			return <SnippetsOutlined style={ICON} />;
		case 10:
			return <SnippetsOutlined style={ICON} />;
	}
};

export const SideMenu = (props: SideMenuProps) => {
	const toPage = (redirection: string) => {
		history.push(redirection);
	};

	const renderButton = (name: string, redirection: string, id: number) => {
		return (
			<div
				key={id}
				style={{ textAlign: 'center', marginTop: 40, cursor: 'pointer' }}
				onClick={() => toPage(redirection)}
			>
				{renderIcon(id)}
				<Text text={name} style={{ color: colors.background, marginTop: 5, fontSize: 10, lineHeight: 1.2 }} />
			</div>
		);
	};

	return (
		<div
			style={{
				width: 120,
				background: `linear-gradient(${colors.secondary},${colors.primary})`,
				display: 'flex',
				padding: '0 20px',
				flexDirection: 'column'
			}}
		>
			{menus.map((menu) => {
				let { redirection, id, name, allow } = menu;
				if (allow) {
					return renderButton(name, redirection, id);
				} else {
					return (
						<Tooltip
							placement="rightTop"
							title={'Coming Soon'}
							overlayStyle={{ fontSize: 10, fontFamily: fonts.textFontFamily }}
							key={id}
						>
							{renderButton(name, redirection, id)}
						</Tooltip>
					);
				}
			})}
		</div>
	);
};
