import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

// Components
import { MainContainer, Subtitle, CustomTable, ModelForm } from '../../components';
import fields from './fields';

// action
import { useListStore } from '../../stores';

const renderChild = ({ data, deleteNews, creatOrUpdateNews }: any) => {
	const handleCreate = (params: Object) => {
		creatOrUpdateNews(params);
	};

	const handleUpdate = (params: object) => {
		creatOrUpdateNews(params);
	};

	const handleDeletion = (id: string) => {
		deleteNews(id);
	};

	return (
		<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Subtitle subtitle="News" preset="page-subtitle" />
				<ModelForm
					formTitle="Create List Item"
					formName={'newNewletter'}
					submitBtnName={'CREATE'}
					submitBtnType={'submit'}
					submitBtnClass="login-button"
					onSubmitAction={handleCreate}
					fields={[
						{
							id: 0,
							name: 'Name',
							type: 'text',
							rules: { required: true, message: 'Please input Name !' }
						},
						{
							id: 0,
							name: 'introduction',
							type: 'textarea',
							rules: { required: true, message: 'Please input detail !' },
							editType: 'textarea'
						}
					]}
				/>
			</div>
			{/* Table */}
			<div style={{ width: '100%', marginTop: 20 }}>
				<CustomTable
					dataList={data || []}
					fields={fields}
					updateAction={handleUpdate}
					deletedAction={handleDeletion}
					redirectLink={'news'}
				/>
			</div>
		</div>
	);
};

const DanceStylePage = () => {
	const [ categoryList, setCategoryList ] = useState<any>([]);

	const { list, getNewsList, creatOrUpdateNews, deleteNews } = useListStore();

	useEffect(
		() => {
			getNewsList();
		},
		[ getNewsList ]
	);

	useEffect(
		() => {
			list && list.length > 0 && setCategoryList(list);
		},
		[ list ]
	);

	let config = {
		data: categoryList,
		creatOrUpdateNews,
		deleteNews
	};

	return <MainContainer child={() => renderChild(config)} />;
};

export default observer(DanceStylePage);
