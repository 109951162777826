import React, { useEffect, useState } from 'react';

// Components
import { Avatar, Dropdown } from 'antd';
import settingMenu from './settingMenu';
import { history } from '../../stores';
// import { logo } from '../../config/style';
// Assets
// const LOGO = `assets/${logo}`;

interface HeaderProps {}

export const Header = (props: HeaderProps) => {
	const [ lastNameChar, setLastNameChar ] = useState('A');

	useEffect(() => {
		let admin: any = localStorage.getItem('admin');
		if (!admin) {
			localStorage.clear();
			history.replace('/');
		}
		const { name = 'Admin' } = JSON.parse(admin) || {};
		name && name[0] && setLastNameChar(name[0].toUpperCase());
	}, []);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				padding: '15px 30px',
				alignItems: 'center',
				boxShadow: '4px 1px 4px #dfdfdf'
			}}
		>
			{/* Logo */}
			<div>
				{/* <a> */}
				{/* <img src={LOGO} width={100} alt={'header-logo'} /> */}
				{/* </a> */}
			</div>

			{/* Avatar */}
			<div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
				<Dropdown overlay={settingMenu} trigger={[ 'click' ]}>
					<Avatar
						style={{ backgroundColor: 'rgb(110,204,219)', verticalAlign: 'middle', cursor: 'pointer' }}
						size="default"
					>
						{lastNameChar}
					</Avatar>
				</Dropdown>
			</div>
		</div>
	);
};
