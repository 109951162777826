import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams, useLocation } from "react-router-dom";
// Components
import { MainContainer, Subtitle, CustomTable, Text } from "../../components";
import fields from "./fields";
import hisFields from "./hisFields";

// action
import { useListStore } from "../../stores";
import moment from "moment";

const renderChild = ({
  data,
  data2,
  tutorList,
  updateTakeCourse,
  updatePurchaseRecord,
  id,
}: any) => {
  let student: any = localStorage.getItem("member");

  student = student ? JSON.parse(student) : student;
  let expired =
    student &&
    student.expireDate &&
    moment(student.expireDate).isBefore(moment())
      ? true
      : false;
  expired = student && student.currentCourse <= 0 ? true : expired;

  const handleUpdate = (params: any) => {
    updateTakeCourse(params);
  };

  const handlePurchaseUpdate = (params: any) => {
    params.memberId = id;
    params.amount = parseInt(params.amount);
    updatePurchaseRecord(params);
  };

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {student && (
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Id" />
            <Text style={{ marginTop: 0, marginLeft: 10 }} text={student.id} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Name" />
            <Text
              style={{ marginTop: 0, marginLeft: 10 }}
              text={student.name}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Phone" />
            <Text
              style={{ marginTop: 0, marginLeft: 10 }}
              text={student.phone}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Email" />
            <Text
              style={{ marginTop: 0, marginLeft: 10 }}
              text={student.email}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Current Course" />
            <Text
              color={expired ? "red" : "green"}
              style={{ marginTop: 0, marginLeft: 10 }}
              text={`${student.currentCourse} (Expired At :${new Date(
                student.expireDate
              ).toLocaleDateString()} ${new Date(
                student.expireDate
              ).toLocaleTimeString()})`}
            />
          </div>
        </div>
      )}

      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Subtitle subtitle="Purchase Course History" preset="page-subtitle" />
      </div>
      {/* Table */}
      <div style={{ width: "100%", marginTop: 20 }}>
        <CustomTable
          dataList={data || []}
          fields={fields}
          allowDelete={false}
          rowHeight={50}
          allowRedirect={false}
          allowEdit={true}
          updateAction={handlePurchaseUpdate}
          editOptions={[
            { value: 1, displayName: 1 },
            { value: 4, displayName: 4 },
            { value: 8, displayName: 8 },
            { value: 12, displayName: 12 },
          ]}
        />
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Subtitle subtitle="Take Course History" preset="page-subtitle" />
      </div>
      <div style={{ width: "100%", marginTop: 20 }}>
        {data2 && data2.length > 0 && (
          <CustomTable
            dataList={data2 || []}
            fields={hisFields}
            allowDelete={false}
            rowHeight={50}
            allowRedirect={false}
            allowEdit={true}
            editOptions={tutorList.map((tutor: any) => {
              const { id, name } = tutor;
              return { value: id, displayName: name };
            })}
            updateAction={handleUpdate}
          />
        )}
      </div>
    </div>
  );
};

const DanceStylePage = () => {
  const [categoryList, setCategoryList] = useState<any>([]);
  const [categoryList2, setCategoryList2] = useState<any>([]);

  const {
    list,
    history,
    getMemberCourse,
    getTakeCourseHisStudent,
    creatOrUpdateCourse,
    updateMember,
    getTutorList,
    tutorList,
    updateTakeCourse,
    updatePurchaseRecord,
  } = useListStore();

  let { id } = useParams<any>();
  useEffect(() => {
    getMemberCourse(id);
    getTakeCourseHisStudent(id);
    getTutorList();
  }, [getMemberCourse, getTakeCourseHisStudent, getTutorList]);

  useEffect(() => {
    setCategoryList(list);
  }, [list]);

  useEffect(() => {
    setCategoryList2(history);
  }, [history]);

  useEffect(() => {}, [tutorList]);

  let config = {
    data: categoryList,
    data2: categoryList2,
    creatOrUpdateCourse,
    updateMember,
    id,
    tutorList,
    updateTakeCourse,
    updatePurchaseRecord,
  };

  return <MainContainer child={() => renderChild(config)} />;
};

export default observer(DanceStylePage);
