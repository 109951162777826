const fields = [
	{
		name: 'ID',
		editable: false,
		dataKey: 'id'
	},
	{
		name: 'Name',
		editable: true,
		dataKey: 'name'
	},
	{
		name: 'Introduction',
		editable: true,
		dataKey: 'introduction',
		editType: 'textarea'
	},
	{
		name: 'image',
		editable: true,
		dataKey: 'imageLink',
		editType: 'image'
	},
	{
		name: 'Create At',
		editable: false,
		dataKey: 'createAt',
		fieldType: 'date'
	},
	{
		name: 'Last Updated At',
		editable: false,
		dataKey: 'lastUpdateAt',
		fieldType: 'date'
	}
];

export default fields;
