const hisFields = [
	{
		name: 'ID',
		editable: false,
		dataKey: 'id',
		width: 50
	},
	{
		name: 'Tutor',
		editable: false,
		dataKey: 'tutorName',
		// fieldType: 'date',
		width: 200
	},
	{
		name: 'Student Name',
		editable: false,
		dataKey: 'memberName',
		// fieldType: 'date',
		width: 200
	},
	{
		name: 'Create At',
		editable: false,
		dataKey: 'createAt',
		fieldType: 'date',
		width: 200
	}
];

export default hisFields;
