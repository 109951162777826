import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

// Components
import { MainContainer, Subtitle, CustomTable, ModelForm } from '../../components';
import fields from './fields';

// action
import { useListStore } from '../../stores';

const renderChild = ({ data, creatOrUpdateOperation }: any) => {
	const handleUpdate = (params: object) => {
		creatOrUpdateOperation(params);
	};

	return (
		<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Subtitle subtitle="Operation Concept" preset="page-subtitle" />
			</div>
			{/* Table */}
			<div style={{ width: '100%', marginTop: 20 }}>
				<CustomTable
					allowDelete={false}
					dataList={data || []}
					fields={fields}
					updateAction={handleUpdate}
					redirectLink={'news'}
				/>
			</div>
		</div>
	);
};

const DanceStylePage = () => {
	const [ categoryList, setCategoryList ] = useState<any>([]);

	const { list, getOperationConcept, creatOrUpdateOperation } = useListStore();

	useEffect(
		() => {
			getOperationConcept();
		},
		[ getOperationConcept ]
	);

	useEffect(
		() => {
			list && list.length > 0 && setCategoryList(list);
		},
		[ list ]
	);

	let config = {
		data: categoryList,
		creatOrUpdateOperation
	};

	return <MainContainer child={() => renderChild(config)} />;
};

export default observer(DanceStylePage);
