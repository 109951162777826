import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

// Components
import {
  MainContainer,
  Subtitle,
  CustomTable,
  ModelForm,
} from "../../components";
import fields from "./fields";
import { Input, List, message, Modal } from "antd";

// action
import { useListStore } from "../../stores";

const { Search } = Input;

const renderChild = ({
  data,
  createMember,
  updateMember,
  onSearchById,
  onSearchByPhoneNumber,
}: any) => {
  const handleCreate = (params: any) => {
    const { birthday, emergencyContact, emergencyContactName } = params;

    params.dob = birthday;
    params.e_contact_name = emergencyContactName;
    params.e_contact = emergencyContact;

    createMember(params);
  };

  const handleUpdate = (params: object) => {
    updateMember(params);
  };

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Subtitle subtitle="Member" preset="page-subtitle" />

        <ModelForm
          formTitle="Create List Item"
          formName={"newNewletter"}
          submitBtnName={"CREATE"}
          submitBtnType={"submit"}
          submitBtnClass="login-button"
          onSubmitAction={handleCreate}
          fields={[
            {
              id: 0,
              name: "Name",
              type: "text",
              rules: { required: true, message: "Please input Name !" },
            },
            {
              id: 1,
              name: "Email",
              type: "text",
              rules: { required: true, message: "Please input Email !" },
            },
            {
              id: 2,
              name: "Phone",
              type: "text",
              rules: { required: true, message: "Please input Email !" },
            },
            {
              id: 3,
              name: "Emergency contact name",
              type: "text",
              rules: {
                required: true,
                message: "Please input Emergency contact name !",
              },
            },
            {
              id: 4,
              name: "Emergency contact",
              type: "text",
              rules: {
                required: true,
                message: "Please input Emergency contact !",
              },
            },
            {
              id: 5,
              name: "Password",
              type: "text",
              rules: { required: true, message: "Please input password!" },
            },
            {
              id: 5,
              name: "Birthday",
              type: "text",
              rules: { required: true, message: "Please input birthday!" },
            },
          ]}
        />

        <Search
          placeholder="Search by phone number"
          onSearch={onSearchByPhoneNumber}
          style={{ width: 200, marginLeft: 20 }}
        />
        <Search
          placeholder="Search by id"
          onSearch={onSearchById}
          style={{ width: 200, marginLeft: 20 }}
        />
      </div>
      {/* Table */}
      <div style={{ width: "100%", marginTop: 20 }}>
        <CustomTable
          dataList={data || []}
          fields={fields}
          allowDelete={false}
          updateAction={handleUpdate}
          redirectLink={"member/details"}
          rowHeight={50}
          allowRedirect={true}
        />
      </div>
    </div>
  );
};

const DanceStylePage = () => {
  const [categoryList, setCategoryList] = useState<any>([]);

  const { memberList, getMember, createMember, updateMember } = useListStore();

  useEffect(() => {
    getMember();
  }, [getMember]);

  useEffect(() => {
    memberList && memberList.length > 0 && setCategoryList(memberList);
  }, [memberList]);

  const onSearchById = (value: any) => {
    const target = memberList.filter((item: any) => item.id == value);
    setCategoryList(target);
  };

  const onSearchByPhoneNumber = (value: any) => {
    const target = memberList.filter((item: any) => item.phone == value);
    setCategoryList(target);
  };

  let config = {
    data: categoryList,
    createMember,
    updateMember,
    onSearchById,
    onSearchByPhoneNumber,
  };

  return <MainContainer child={() => renderChild(config)} />;
};

export default observer(DanceStylePage);
