import { Method } from "axios";
require("dotenv").config();

const { REACT_APP_ADMIN_API_ENDPOINT = "http://127.0.0.1:6005" } = process.env;

const endpoint = `${REACT_APP_ADMIN_API_ENDPOINT}/api`;
const POST: Method = "POST";
const PUT: Method = "PUT";
const GET: Method = "GET";
const DELETE: Method = "DELETE";

const authApi = {
  login: {
    method: POST,
    url: `${endpoint}/admin/login`,
  },
};

const imageApi = {
  upload: {
    method: POST,
    url: `${endpoint}/upload`,
  },
};

const danceStyleApi = {
  createOrUpdate: {
    method: POST,
    url: `${endpoint}/dancestyle`,
  },
  list: {
    method: GET,
    url: `${endpoint}/dancestyle`,
  },
  delete: {
    method: DELETE,
    url: `${endpoint}/dancestyle`,
  },
};

const tutorApi = {
  createOrUpdate: {
    method: POST,
    url: `${endpoint}/tutor`,
  },
  list: {
    method: GET,
    url: `${endpoint}/tutor`,
  },
  delete: {
    method: DELETE,
    url: `${endpoint}/tutor`,
  },
};

const memberApi = {
  update: {
    method: POST,
    url: `${endpoint}/member`,
  },
  create: {
    method: PUT,
    url: `${endpoint}/member`,
  },
  list: {
    method: GET,
    url: `${endpoint}/members`,
  },
  delete: {
    method: DELETE,
    url: `${endpoint}/member`,
  },
};

const lessonApi = {
  createOrUpdate: {
    method: POST,
    url: `${endpoint}/dancestyle`,
  },
  list: {
    method: GET,
    url: `${endpoint}/dancestyle`,
  },
  delete: {
    method: DELETE,
    url: `${endpoint}/dancestyle`,
  },
};

const pastEventApi = {
  createOrUpdate: {
    method: POST,
    url: `${endpoint}/event`,
  },
  list: {
    method: GET,
    url: `${endpoint}/events`,
  },
  delete: {
    method: DELETE,
    url: `${endpoint}/event`,
  },
};

const newsApi = {
  createOrUpdate: {
    method: POST,
    url: `${endpoint}/news`,
  },
  list: {
    method: GET,
    url: `${endpoint}/news`,
  },
  delete: {
    method: DELETE,
    url: `${endpoint}/news`,
  },
};

const contactUsApi = {
  createOrUpdate: {
    method: POST,
    url: `${endpoint}/contactus`,
  },
  list: {
    method: GET,
    url: `${endpoint}/contactus`,
  },
};

const timetableApi = {
  createOrUpdate: {
    method: POST,
    url: `${endpoint}/timetable`,
  },
  list: {
    method: GET,
    url: `${endpoint}/timetable`,
  },
};

const priceApi = {
  createOrUpdate: {
    method: POST,
    url: `${endpoint}/price`,
  },
  list: {
    method: GET,
    url: `${endpoint}/price`,
  },
};

const operationConceptApi = {
  createOrUpdate: {
    method: POST,
    url: `${endpoint}/operationconcept`,
  },
  list: {
    method: GET,
    url: `${endpoint}/operationconcept`,
  },
};

const courseApi = {
  createOrUpdate: {
    method: POST,
    url: `${endpoint}/course`,
  },
  updatePurchaseRecord: {
    method: PUT,
    url: `${endpoint}/course`,
  },
  list: {
    method: GET,
    url: `${endpoint}/course/{ID}`,
  },
  takeCourse: {
    method: POST,
    url: `${endpoint}/course/history`,
  },
  updateTakeCourse: {
    method: PUT,
    url: `${endpoint}/course/history`,
  },
  takeCourseHisBysStudent: {
    method: GET,
    url: `${endpoint}/course/student/history/{ID}`,
  },
  takeCourseHis: {
    method: GET,
    url: `${endpoint}/course/history/{ID}`,
  },
  takeCourseAllHis: {
    method: GET,
    url: `${endpoint}/course/history/all`,
  },
};

export {
  authApi,
  imageApi,
  danceStyleApi,
  pastEventApi,
  memberApi,
  lessonApi,
  tutorApi,
  timetableApi,
  priceApi,
  contactUsApi,
  newsApi,
  operationConceptApi,
  courseApi,
};
