import React from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import Login from "./pages/login";
import DanceStyle from "./pages/danceStyle";
import Tutor from "./pages/tutor";
import TutorDetails from "./pages/tutorDetails";

import PastEvent from "./pages/pastEvent";
import Member from "./pages/member";
import MemberDetails from "./pages/memberDetails";

import News from "./pages/news";
import ContactUs from "./pages/contactus";
import Price from "./pages/price";
import TimeTable from "./pages/timetable";
import OperationConcept from "./pages/operationConcept";
import Operation from "./pages/operation";
export default function Routers() {
  return (
    <Router history={history}>
      <div
        style={{ display: "flex", flex: 1, minHeight: "100vh", minWidth: 1024 }}
      >
        <Switch>
          <Route path={"/"} exact component={Login} />
          <Route path={"/dancestyle"} exact component={DanceStyle} />
          <Route path={"/tutor/details/:id"} exact component={TutorDetails} />
          <Route path={"/tutor"} exact component={Tutor} />

          <Route path={"/pastevent"} exact component={PastEvent} />
          <Route path={"/member/details/:id"} exact component={MemberDetails} />
          <Route path={"/member"} exact component={Member} />
          <Route path={"/news"} exact component={News} />
          <Route path={"/contactus"} exact component={ContactUs} />
          <Route path={"/price"} exact component={Price} />
          <Route path={"/timetable"} exact component={TimeTable} />
          <Route
            path={"/operationconcept"}
            exact
            component={OperationConcept}
          />
          <Route path={"/operation"} exact component={Operation} />
        </Switch>
      </div>
    </Router>
  );
}
