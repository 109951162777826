import React, { useEffect, useState } from "react";
// Type
import { iForm } from "./types";
import { ButtonHtmlType } from "../button/types";

// Style
import { Form as AntdForm, Input, InputNumber, Select } from "antd";
import { Button } from "../";
import {
  UserOutlined,
  LockOutlined,
  LoginOutlined,
  PhoneOutlined,
  MailOutlined,
  LockTwoTone,
} from "@ant-design/icons";
// Utils
import { toCamelCase } from "../../utils/transformText";
import { formatDateForInput } from "../../utils";
interface FormProps {
  fields: Array<iForm>;
  formName: string;
  formStyle?: object;
  onFinish?: any;
  onFinishFailed?: any;
  submitBtnName: string;
  submitBtnType: ButtonHtmlType;
  submitBtnClass: string;
  options?: Array<any>;
  initialValues?: object;
  secondBtnName?: string;
  secondBtnFunc?: Function;
  secondBtnStyle?: object;
}
const { Option } = Select;
const { TextArea } = Input;

export const Form = (props: FormProps) => {
  let {
    fields: itemFields,
    formName,
    formStyle,
    onFinish,
    onFinishFailed,
    submitBtnName,
    submitBtnType,
    submitBtnClass,
    initialValues,
    secondBtnName,
    secondBtnFunc,
    secondBtnStyle,
  } = props;

  const [form] = AntdForm.useForm();
  const [forDependsOption, setForDependsOption] = useState<any>({});

  useEffect(() => {}, [forDependsOption]);

  const renderPrefix = (type: string) => {
    switch (type) {
      case "username":
      case "fullName":
        return <UserOutlined className="site-form-item-icon" />;
      case "phone":
        return <PhoneOutlined className="site-form-item-icon" />;
      case "level":
        return <LockTwoTone className="site-form-item-icon" />;
      case "email":
        return <MailOutlined className="site-form-item-icon" />;
      case "password":
      case "confirmPassword":
        return <LockOutlined className="site-form-item-icon" />;
      case "token":
        return <LoginOutlined className="site-form-item-icon" />;
      default:
        return null;
    }
  };

  const handleInputChange = (
    type: string,
    dependsOnField: string,
    fieldName: any,
    inputValue: any
  ) => {
    let object: any = { [fieldName]: inputValue };
    setForDependsOption({
      ...forDependsOption,
      ...object,
    });
  };

  const submitForm = (param: any) => {
    onFinish && onFinish(param);
    form.resetFields();
  };

  return (
    <div>
      <AntdForm
        form={form}
        name={formName}
        style={formStyle || {}}
        onFinish={submitForm}
        onFinishFailed={onFinishFailed || undefined}
        initialValues={initialValues || undefined}
      >
        {/* Fields */}
        {itemFields.map((field: any) => {
          const {
            id,
            name,
            optionName = "",
            rules,
            type,
            dependsOnField,
          } = field;
          let { options } = field;
          let { value } = field;
          let fieldName = toCamelCase(name.toLowerCase());
          // just for options
          const optionTypes = ["parentOptions", "depandsOptions", "options"];

          let optionsValue = optionTypes.includes(type)
            ? forDependsOption[fieldName] || value
            : "";

          if (type === "date" && value) {
            value = formatDateForInput(value);
          }

          return (
            <AntdForm.Item
              name={fieldName}
              rules={[rules || { required: false }]}
              key={id}
              hasFeedback
            >
              {type === "number" ? (
                <InputNumber
                  key={`input${id}`}
                  max={field.max}
                  min={field.min}
                  placeholder={name}
                  style={{ fontSize: 12, width: "100%" }}
                />
              ) : optionTypes.includes(type) ? (
                <Select
                  onChange={(value) =>
                    handleInputChange(type, dependsOnField, fieldName, value)
                  }
                  value={optionsValue}
                  style={{ width: "100%" }}
                  placeholder={name}
                  key={`input${id}`}
                >
                  {options &&
                    options.length > 0 &&
                    options.map((option: any) => {
                      return (
                        <Option
                          key={
                            optionName
                              ? `input${option.id}`
                              : `inputOption${option}`
                          }
                          value={optionName ? option.id : option}
                        >
                          {optionName ? option[`${optionName}`] : option}
                        </Option>
                      );
                    })}
                </Select>
              ) : type === "textarea" ? (
                <TextArea
                  placeholder={name}
                  key={`input${id}`}
                  value={value ? value : null}
                  rows={6}
                />
              ) : (
                <Input
                  key={`input${id}`}
                  placeholder={name}
                  value={value ? value : null}
                  type={type}
                  style={{ fontSize: 12 }}
                  prefix={renderPrefix(fieldName)}
                />
              )}
            </AntdForm.Item>
          );
        }, [])}

        {/* Complete */}
        <AntdForm.Item>
          <Button
            buttonName={submitBtnName}
            htmlType={submitBtnType}
            className={submitBtnClass}
          />
        </AntdForm.Item>

        {secondBtnStyle && secondBtnFunc && secondBtnName && (
          <AntdForm.Item>
            <Button
              style={secondBtnStyle}
              onClick={() => secondBtnFunc && secondBtnFunc()}
              buttonName={secondBtnName}
              htmlType="button"
              className={submitBtnClass}
            />
          </AntdForm.Item>
        )}
      </AntdForm>
    </div>
  );
};
