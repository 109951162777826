import { action } from "mobx";
import { getLocalStorage } from "../utils";
import axios, { Method } from "axios";
import { message } from "antd";
export class ApiStore {
  @action.bound
  callApi(method: Method, url: string, params: any = null) {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await getLocalStorage("token");
        axios({
          method: method,
          url: url,
          data: params,
          headers: {
            authentication: token || null,
          },
        })
          .then((response) => {
            resolve(response ? response.data : null);
          })
          .catch(async (error) => {
            const { status, data } = (error && error.response) || {};
            if (status === 401) {
              // need to double check here...
              message.error("Please login again");
              return false;
            }
            reject(data);
          });
      } catch (e) {
        reject({ code: 500, message: "Please try again later", data: null });
      }
    });
  }
}

export const STORE_API = "apiStore";
