import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Input, List, message, Modal } from "antd";
// Components
import { MainContainer, Subtitle, Button, Text } from "../../components";

// action
import { useListStore } from "../../stores";
import moment from "moment";

const { Search } = Input;

const renderChild = ({
  tutorList,
  member,
  onSearchById,
  onSearchByPhoneNumber,
  submitPurchase,
  submitTakeCourse,
}: any) => {
  let expired =
    member && member.expireDate && moment(member.expireDate).isBefore(moment())
      ? true
      : false;
  expired = member && member.currentCourse <= 0 ? true : expired;
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <Subtitle subtitle="Search by id" />
      <Search
        placeholder="Search by id"
        onSearch={onSearchById}
        style={{ width: 200 }}
      />

      <br />
      <br />
      <br />

      <Subtitle subtitle="Search by phone number" />
      <Search
        placeholder="Search by phone number"
        onSearch={onSearchByPhoneNumber}
        style={{ width: 200 }}
      />

      <br />
      <br />
      <br />

      {member && (
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Id" />
            <Text style={{ marginTop: 0, marginLeft: 10 }} text={member.id} />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Name" />
            <Text style={{ marginTop: 0, marginLeft: 10 }} text={member.name} />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Phone" />
            <Text
              style={{ marginTop: 0, marginLeft: 10 }}
              text={member.phone}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text text="Current Course" />
            <Text
              color={expired ? "red" : "green"}
              style={{
                marginTop: 0,
                marginLeft: 10,
              }}
              text={`${member.currentCourse} (Expired At :${new Date(
                member.expireDate
              ).toLocaleDateString()} ${new Date(
                member.expireDate
              ).toLocaleTimeString()})`}
            />
          </div>
        </div>
      )}

      <Subtitle subtitle="Spend" />
      <br />
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={tutorList}
        renderItem={(item: any) => (
          <List.Item>
            <Button
              onClick={() => {
                if (expired) {
                  message.error("Expired, cannot take class");
                  return;
                }
                submitTakeCourse(item);
              }}
              buttonName={item.name}
            />
          </List.Item>
        )}
      />
      <br />
      <br />
      <br />

      <Subtitle subtitle="Recharged" />
      <div style={{ flexDirection: "row", display: "flex" }}>
        <Button
          onClick={() => submitPurchase(1)}
          style={{ margin: 10 }}
          buttonName="1 lesson"
        />
        <Button
          onClick={() => submitPurchase(4)}
          style={{ margin: 10 }}
          buttonName="4 lesson"
        />
        <Button
          onClick={() => submitPurchase(8)}
          style={{ margin: 10 }}
          buttonName="8 lesson"
        />
        <Button
          onClick={() => submitPurchase(12)}
          style={{ margin: 10 }}
          buttonName="12 lesson"
        />
      </div>
    </div>
  );
};

const DanceStylePage = () => {
  const [member, setMember] = useState<any>(null);

  const {
    memberList,
    tutorList,
    getMember,
    getTutorList,
    creatOrUpdateCourse,
    takeCourse,
  } = useListStore();
  useEffect(() => {
    getMember();
    getTutorList();
  }, []);

  useEffect(() => {}, [setMember]);

  const onSearchById = (value: any) => {
    const target = memberList.find((item: any) => item.id == value);
    setMember(target);
  };

  const onSearchByPhoneNumber = (value: any) => {
    const target = memberList.find((item: any) => item.phone == value);
    setMember(target);
  };

  const submitPurchase = async (amount: number) => {
    if (!member) {
      message.error("Please select student first !!!");
      return false;
    }

    let expireDateMonth = 1;
    switch (amount) {
      case 1:
      case 4:
        expireDateMonth = 1;
        break;
      case 8:
        expireDateMonth = 2;
        break;
      case 12:
        expireDateMonth = 3;
        break;
    }

    Modal.confirm({
      title: "Confirm to purchase class",
      content: `Do you confirm to purchase ${amount} classes?.`,
      okText: "YES",
      cancelText: "NO",
      onOk: async () => {
        await creatOrUpdateCourse({
          amount,
          memberId: member.id,
          expireDateMonth,
        });

        message.info("Purchased Successful");
        const newMemberList = await getMember();

        const target = newMemberList.find((item: any) => item.id == member.id);
        setMember(target);
      },
    });
  };

  const submitTakeCourse = async (tutor: any) => {
    if (!member) {
      message.error("Please select student first !!!");
      return false;
    }

    Modal.confirm({
      title: "Confirm to take class",
      content: `Do you confirm to take ${tutor.name} class?.`,
      okText: "YES",
      cancelText: "NO",
      onOk: async () => {
        await takeCourse(tutor.id, [member.id]);
        message.info("Took class Successful");
        const newMemberList = await getMember();

        const target = newMemberList.find((item: any) => item.id == member.id);
        setMember(target);
      },
    });
  };

  let config = {
    memberList,
    tutorList,
    creatOrUpdateCourse,
    takeCourse,
    onSearchById,
    onSearchByPhoneNumber,
    member,
    submitPurchase,
    submitTakeCourse,
  };

  return <MainContainer child={() => renderChild(config)} />;
};

export default observer(DanceStylePage);
